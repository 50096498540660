<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 销售结算
 * @Date: 2020-12-25 11:30:37
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-25 17:29:10
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/sale.vue
-->
<template>
  <div class="user_content">
    <div class="waller_box">
      <div class="waller_total"><span>¥</span><span>{{ total }}</span></div>
      <div class="title">总计</div>
    </div>
    <div class="waller_list">
      <van-cell
        v-for="(item,index) in allType"
        :key="index"
        :title="item.title"
        :value="'¥ ' + item.amount"
        is-link
        @click="tiplink(item)"
      />
      <div v-if="allType === ''">
        无数据
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Cell,
  CellGroup,
  Icon,
  Toast
} from 'vant'

Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(Toast)
import {
  saleMoney
} from '@/services/userApi'

export default {
  filters: {
    style(v) {
      const map = {
        1: '待确定',
        2: '提现中',
        3: '提现成功',
        4: '提现失败'
      }
      return map[v]
    }
  },
  data() {
    return {
      total: '',
      allType: []
    }
  },
  mounted() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      saleMoney().then(res => {
        if (Number(res.code) === 200) {
          this.total = res.data.total
          this.allType = res.data.allType
        } else {
          Toast(res.msg)
        }
      })
    },
    tiplink(t) {
      this.$router.push({ path: '/commodity', query: { module: t.module }})
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .waller_box {
    background: #F7263C;
    height: 150px;
    text-align: center;
  }

  .waller_total {
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      margin-top: 30px;
      color: #FFFFFF;
      font-size: 15px;
    }

    & > span:nth-child(2) {
      font-size: 39px;
      font-weight: 600;
    }
  }

  .title {
    color: #fff;
  }

  .van-cell__title {
    text-align: left;
  }

  .waller_list {
    width: 93%;
    position: absolute;
    top: 120px;
    background: #FFFFFF;
    border-radius: 7px;
    margin: 13px;
    padding: 10px;
    height: calc(100vh - 150px);
    overflow: scroll;

    .setttitle {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }

    .settitem {
      display: flex;
      padding: 10px 0 3px;
      color: #333333;

      & > span:nth-child(1) {
        flex: 6;
        text-align: left;
      }

      & > span:nth-child(2) {
        flex: 3;
      }

      & > span:nth-child(3) {
        flex: 3;
        color: #E33F44;
        font-size: 13px;
      }
    }
  }
}
</style>
